/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.20.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedVersionModelUpdateRequest
 */
export interface PatchedVersionModelUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedVersionModelUpdateRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedVersionModelUpdateRequest
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedVersionModelUpdateRequest
     */
    phase?: number | null;
}

/**
 * Check if a given object implements the PatchedVersionModelUpdateRequest interface.
 */
export function instanceOfPatchedVersionModelUpdateRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatchedVersionModelUpdateRequestFromJSON(json: any): PatchedVersionModelUpdateRequest {
    return PatchedVersionModelUpdateRequestFromJSONTyped(json, false);
}

export function PatchedVersionModelUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedVersionModelUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phase': !exists(json, 'phase') ? undefined : json['phase'],
    };
}

export function PatchedVersionModelUpdateRequestToJSON(value?: PatchedVersionModelUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'name': value.name,
        'phase': value.phase,
    };
}

