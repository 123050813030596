/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.20.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `Bezig met initialiseren` - Bezig met initialiseren
 * * `Toegang controleren` - Toegang controleren
 * * `Bestand ophalen` - Bestand ophalen
 * * `Data invullen` - Data invullen
 * * `Kaartdata laden` - Kaartdata laden
 * * `Nieuwe sessie wordt gestart` - Nieuwe sessie wordt gestart
 * * `Bestand wordt geserveerd` - Bestand wordt geserveerd
 * @export
 */
export const ProgressEnum = {
    BezigMetInitialiseren: 'Bezig met initialiseren',
    ToegangControleren: 'Toegang controleren',
    BestandOphalen: 'Bestand ophalen',
    DataInvullen: 'Data invullen',
    KaartdataLaden: 'Kaartdata laden',
    NieuweSessieWordtGestart: 'Nieuwe sessie wordt gestart',
    BestandWordtGeserveerd: 'Bestand wordt geserveerd'
} as const;
export type ProgressEnum = typeof ProgressEnum[keyof typeof ProgressEnum];


export function ProgressEnumFromJSON(json: any): ProgressEnum {
    return ProgressEnumFromJSONTyped(json, false);
}

export function ProgressEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgressEnum {
    return json as ProgressEnum;
}

export function ProgressEnumToJSON(value?: ProgressEnum | null): any {
    return value as any;
}

