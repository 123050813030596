/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.20.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientUpdateTemplateModelRequest
 */
export interface ClientUpdateTemplateModelRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientUpdateTemplateModelRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ClientUpdateTemplateModelRequest
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof ClientUpdateTemplateModelRequest
     */
    active: number;
}

/**
 * Check if a given object implements the ClientUpdateTemplateModelRequest interface.
 */
export function instanceOfClientUpdateTemplateModelRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "active" in value;

    return isInstance;
}

export function ClientUpdateTemplateModelRequestFromJSON(json: any): ClientUpdateTemplateModelRequest {
    return ClientUpdateTemplateModelRequestFromJSONTyped(json, false);
}

export function ClientUpdateTemplateModelRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientUpdateTemplateModelRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': json['description'],
        'active': json['active'],
    };
}

export function ClientUpdateTemplateModelRequestToJSON(value?: ClientUpdateTemplateModelRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'active': value.active,
    };
}

