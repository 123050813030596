// import ButtonList from "@shared/components/ButtonList";
import React from "react";
import ButtonList from "./ButtonList";
import "./MessagePopup.css";
interface MessagePopupProps {
  message: string;
  massageTitle: string;
  okayCallback: () => void;
  cancelCallback: () => void;
  okayButtonText?: string;
  cancelButtonText?: string;
  warning?: boolean;
  TermOfUse?: boolean;
}

export default class MessagePopup extends React.Component<MessagePopupProps> {
  render() {
    const okayButtonText: string = this.props.okayButtonText
      ? this.props.okayButtonText
      : "Verwijderen";
    const cancelButtonText: string = this.props.cancelButtonText
      ? this.props.cancelButtonText
      : "Annuleren";

    const warning: boolean = this.props.warning ?? true;
    return (
      <div id="MessagePopup">
        <div id="MessagePopup-Section">
          <div id="MessagePopup-Title">{this.props.massageTitle}</div>
          {(() => {
            if (this.props.TermOfUse !== undefined && this.props.TermOfUse) {
              return (
                <div id="TermsOfUse">
                  <p>
                    Algemene Gebruikersvoorwaarden rekenmodellen behorende bij
                    GrexManager ©
                  </p>
                  <h4>Artikel 1. TOEPASBAARHEID</h4>
                  <ol>
                    <li>
                      Deze algemene gebruikersvoorwaarden zijn van toepassing op
                      alle door gecontracteerd ingeschakelde medewerkers: vaste
                      medewerkers, tijdelijke medewerkers, ingeschakelde
                      medewerkers derden.
                    </li>
                    <li>
                      Bij aanpassing van de gebruikersvoorwaarden dient u deze
                      opnieuw te bevestigen. U krijgt hier vanzelf een melding
                      van.
                    </li>
                    <li>
                      Deze gebruikersvoorwaarden zijn van toepassing op alle
                      binnen GrexManager beschikbaar gestelde rekenmodellen.
                    </li>
                  </ol>
                  <h4>Artikel 2. VOORWAARDEN</h4>
                  <ul>
                    <li>
                      Op de hosting, beschikbaarstelling van de software en
                      daaraan gerelateerde services, zijn de door de VNG
                      opgestelde{" "}
                      <strong>
                        leveringsvoorwaarden voor IT (de GIBIT voorwaarden)
                      </strong>{" "}
                      van toepassing. De van toepassing zijnde versie van de
                      GIBIT is de versie zoals overeengekomen in de GrexManager
                      overeenkomst tussen uw organisatie en Metafoor Ruimtelijke
                      Ontwikkeling B.V. Nadere informatie over de GIBIT vindt u
                      op https://www.vngrealisatie.nl/gibit.
                    </li>
                    <li>
                      Aanvullend op bovengenoemde GIBIT voorwaarden geldt het
                      volgende:
                      <ul>
                        <li>
                          De door Metafoor Ruimtelijke Ontwikkeling gehouden
                          intellectuele eigendomsrechten of licenties worden op
                          geen enkele wijze in (sub)licentie gegeven of
                          overgedragen aan derden.
                        </li>
                        <li>
                          Elke vorm van openbaarmaking of
                          verveelvoudiging/reproductie (zulks in de ruimste zin
                          van het woord), ieder gebruik van een kopie, of het
                          verveelvoudigen of verspreiden van (een gedeelte van)
                          de rekenmodellen, is strikt verboden. Onder het verbod
                          op openbaarmaking wordt onder meer verstaan een verbod
                          op het distribueren, verspreiden en/of beschikbaar
                          stellen van de (Excel) rekenmodellen en (onderdelen
                          van) de GrexManager webschil op welke wijze dan ook.
                          Vanzelfsprekend is het voor licentiehouders wel
                          toegestaan rapportages, uitgangspunten van
                          berekeningen en uitkomsten van berekeningen en
                          begrotingen te gebruiken ten behoeve van rapportages
                          en verslaglegging.
                        </li>
                        <li>
                          Indien GrexManager gebruikt gaat worden door personeel
                          dat niet direct verbonden is aan uw organisatie
                          (detachering en/of inhuur) draagt uw organisatie de
                          verplichting dat de gedetacheerde en/of ingehuurde met
                          Metafoor Ruimtelijke Ontwikkeling een
                          geheimhoudingsovereenkomst sluit.
                        </li>
                        <li>
                          Rekenmodellen zoeken contact met de database. Daarbij
                          worden technische- en gebruiksgegevens verkregen die
                          alleen voor daartoe geautoriseerde personen
                          raadpleegbaar zijn.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p>Versie: 202502211200</p>
                </div>
              );
            }
          })()}
          <div
            id="MessagePopup-Text"
            dangerouslySetInnerHTML={{ __html: this.props.message }}
          ></div>
          <div id="MessagePopup-Buttons">
            <ButtonList
              data={[
                {
                  title: okayButtonText,
                  callback: () => {
                    this.props.okayCallback();
                  },
                  warning: warning,
                },
                {
                  title: cancelButtonText,
                  callback: () => {
                    this.props.cancelCallback();
                  },
                },
              ]}
            ></ButtonList>
          </div>
        </div>
      </div>
    );
  }
}
