/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.20.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SAMLServiceProvider,
  SAMLServiceResponse,
} from '../models';
import {
    SAMLServiceProviderFromJSON,
    SAMLServiceProviderToJSON,
    SAMLServiceResponseFromJSON,
    SAMLServiceResponseToJSON,
} from '../models';

export interface ApiGrexsuperadminClientSamlCreateRequest {
    id: number;
    sAMLServiceProvider: SAMLServiceProvider;
}

export interface ApiGrexsuperadminClientSamlDestroyRequest {
    id: number;
}

export interface ApiGrexsuperadminClientSamlRetrieveRequest {
    id: number;
}

/**
 * 
 */
export class GrexSuperAdminSAMLApi extends runtime.BaseAPI {

    /**
     * create saml
     */
    async apiGrexsuperadminClientSamlCreateRaw(requestParameters: ApiGrexsuperadminClientSamlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SAMLServiceResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexsuperadminClientSamlCreate.');
        }

        if (requestParameters.sAMLServiceProvider === null || requestParameters.sAMLServiceProvider === undefined) {
            throw new runtime.RequiredError('sAMLServiceProvider','Required parameter requestParameters.sAMLServiceProvider was null or undefined when calling apiGrexsuperadminClientSamlCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{id}/saml/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SAMLServiceProviderToJSON(requestParameters.sAMLServiceProvider),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SAMLServiceResponseFromJSON(jsonValue));
    }

    /**
     * create saml
     */
    async apiGrexsuperadminClientSamlCreate(requestParameters: ApiGrexsuperadminClientSamlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SAMLServiceResponse> {
        const response = await this.apiGrexsuperadminClientSamlCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete saml
     */
    async apiGrexsuperadminClientSamlDestroyRaw(requestParameters: ApiGrexsuperadminClientSamlDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexsuperadminClientSamlDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{id}/saml/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * delete saml
     */
    async apiGrexsuperadminClientSamlDestroy(requestParameters: ApiGrexsuperadminClientSamlDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apiGrexsuperadminClientSamlDestroyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * load saml
     */
    async apiGrexsuperadminClientSamlRetrieveRaw(requestParameters: ApiGrexsuperadminClientSamlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SAMLServiceResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexsuperadminClientSamlRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexsuperadmin/client/{id}/saml/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SAMLServiceResponseFromJSON(jsonValue));
    }

    /**
     * load saml
     */
    async apiGrexsuperadminClientSamlRetrieve(requestParameters: ApiGrexsuperadminClientSamlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SAMLServiceResponse> {
        const response = await this.apiGrexsuperadminClientSamlRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
