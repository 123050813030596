/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.20.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubregionUpdate
 */
export interface SubregionUpdate {
    /**
     * 
     * @type {number}
     * @memberof SubregionUpdate
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof SubregionUpdate
     */
    description: string;
}

/**
 * Check if a given object implements the SubregionUpdate interface.
 */
export function instanceOfSubregionUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function SubregionUpdateFromJSON(json: any): SubregionUpdate {
    return SubregionUpdateFromJSONTyped(json, false);
}

export function SubregionUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubregionUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
    };
}

export function SubregionUpdateToJSON(value?: SubregionUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
    };
}

