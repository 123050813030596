/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.20.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MapviewerErrorEnum } from './MapviewerErrorEnum';
import {
    MapviewerErrorEnumFromJSON,
    MapviewerErrorEnumFromJSONTyped,
    MapviewerErrorEnumToJSON,
} from './MapviewerErrorEnum';
import type { ProgressEnum } from './ProgressEnum';
import {
    ProgressEnumFromJSON,
    ProgressEnumFromJSONTyped,
    ProgressEnumToJSON,
} from './ProgressEnum';

/**
 * 
 * @export
 * @interface ModelOpenStatusResponse
 */
export interface ModelOpenStatusResponse {
    /**
     * 
     * @type {ProgressEnum}
     * @memberof ModelOpenStatusResponse
     */
    progress?: ProgressEnum;
    /**
     * 
     * @type {MapviewerErrorEnum}
     * @memberof ModelOpenStatusResponse
     */
    mapviewerError?: MapviewerErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof ModelOpenStatusResponse
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOpenStatusResponse
     */
    filenameOrig?: string;
}

/**
 * Check if a given object implements the ModelOpenStatusResponse interface.
 */
export function instanceOfModelOpenStatusResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModelOpenStatusResponseFromJSON(json: any): ModelOpenStatusResponse {
    return ModelOpenStatusResponseFromJSONTyped(json, false);
}

export function ModelOpenStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelOpenStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'progress': !exists(json, 'progress') ? undefined : ProgressEnumFromJSON(json['progress']),
        'mapviewerError': !exists(json, 'mapviewer_error') ? undefined : MapviewerErrorEnumFromJSON(json['mapviewer_error']),
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'filenameOrig': !exists(json, 'filename_orig') ? undefined : json['filename_orig'],
    };
}

export function ModelOpenStatusResponseToJSON(value?: ModelOpenStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'progress': ProgressEnumToJSON(value.progress),
        'mapviewer_error': MapviewerErrorEnumToJSON(value.mapviewerError),
        'uuid': value.uuid,
        'filename_orig': value.filenameOrig,
    };
}

