/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.20.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Log } from './Log';
import {
    LogFromJSON,
    LogFromJSONTyped,
    LogToJSON,
} from './Log';

/**
 * 
 * @export
 * @interface LogResponse
 */
export interface LogResponse {
    /**
     * 
     * @type {Array<Log>}
     * @memberof LogResponse
     */
    logRows: Array<Log>;
    /**
     * 
     * @type {number}
     * @memberof LogResponse
     */
    pages: number;
}

/**
 * Check if a given object implements the LogResponse interface.
 */
export function instanceOfLogResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "logRows" in value;
    isInstance = isInstance && "pages" in value;

    return isInstance;
}

export function LogResponseFromJSON(json: any): LogResponse {
    return LogResponseFromJSONTyped(json, false);
}

export function LogResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'logRows': ((json['log_rows'] as Array<any>).map(LogFromJSON)),
        'pages': json['pages'],
    };
}

export function LogResponseToJSON(value?: LogResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'log_rows': ((value.logRows as Array<any>).map(LogToJSON)),
        'pages': value.pages,
    };
}

