/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.20.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `manual` - Manual
 * * `instructional_video` - Instructional Video
 * * `release_note` - Release Note
 * @export
 */
export const DocumentationTypeEnum = {
    Manual: 'manual',
    InstructionalVideo: 'instructional_video',
    ReleaseNote: 'release_note'
} as const;
export type DocumentationTypeEnum = typeof DocumentationTypeEnum[keyof typeof DocumentationTypeEnum];


export function DocumentationTypeEnumFromJSON(json: any): DocumentationTypeEnum {
    return DocumentationTypeEnumFromJSONTyped(json, false);
}

export function DocumentationTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentationTypeEnum {
    return json as DocumentationTypeEnum;
}

export function DocumentationTypeEnumToJSON(value?: DocumentationTypeEnum | null): any {
    return value as any;
}

