/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.20.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectRoleRequest } from './ProjectRoleRequest';
import {
    ProjectRoleRequestFromJSON,
    ProjectRoleRequestFromJSONTyped,
    ProjectRoleRequestToJSON,
} from './ProjectRoleRequest';

/**
 * 
 * @export
 * @interface ProjectUpdateRequest
 */
export interface ProjectUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateRequest
     */
    description: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateRequest
     */
    organisation: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateRequest
     */
    number: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateRequest
     */
    projectmanager: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdateRequest
     */
    subregion: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectUpdateRequest
     */
    typeProject: number;
    /**
     * 
     * @type {Array<ProjectRoleRequest>}
     * @memberof ProjectUpdateRequest
     */
    roles: Array<ProjectRoleRequest>;
}

/**
 * Check if a given object implements the ProjectUpdateRequest interface.
 */
export function instanceOfProjectUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "organisation" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "projectmanager" in value;
    isInstance = isInstance && "subregion" in value;
    isInstance = isInstance && "typeProject" in value;
    isInstance = isInstance && "roles" in value;

    return isInstance;
}

export function ProjectUpdateRequestFromJSON(json: any): ProjectUpdateRequest {
    return ProjectUpdateRequestFromJSONTyped(json, false);
}

export function ProjectUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'organisation': json['organisation'],
        'name': json['name'],
        'number': json['number'],
        'projectmanager': json['projectmanager'],
        'subregion': json['subregion'],
        'typeProject': json['type_project'],
        'roles': ((json['roles'] as Array<any>).map(ProjectRoleRequestFromJSON)),
    };
}

export function ProjectUpdateRequestToJSON(value?: ProjectUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'organisation': value.organisation,
        'name': value.name,
        'number': value.number,
        'projectmanager': value.projectmanager,
        'subregion': value.subregion,
        'type_project': value.typeProject,
        'roles': ((value.roles as Array<any>).map(ProjectRoleRequestToJSON)),
    };
}

