/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 25.02.20.13.22
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VersionModelCreateRequest
 */
export interface VersionModelCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof VersionModelCreateRequest
     */
    description: string | null;
    /**
     * 
     * @type {string}
     * @memberof VersionModelCreateRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof VersionModelCreateRequest
     */
    phase: number | null;
    /**
     * 
     * @type {number}
     * @memberof VersionModelCreateRequest
     */
    project: number;
}

/**
 * Check if a given object implements the VersionModelCreateRequest interface.
 */
export function instanceOfVersionModelCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "phase" in value;
    isInstance = isInstance && "project" in value;

    return isInstance;
}

export function VersionModelCreateRequestFromJSON(json: any): VersionModelCreateRequest {
    return VersionModelCreateRequestFromJSONTyped(json, false);
}

export function VersionModelCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionModelCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': json['description'],
        'name': json['name'],
        'phase': json['phase'],
        'project': json['project'],
    };
}

export function VersionModelCreateRequestToJSON(value?: VersionModelCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'name': value.name,
        'phase': value.phase,
        'project': value.project,
    };
}

