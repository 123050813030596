import React from "react";
import "./Button.css";

interface ButtonProps {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  noMargin?: boolean;
}

export default class Button extends React.Component<ButtonProps> {
  render() {
    return (
      <div
        className={`${this.props.disabled ? "Button disabled" : "Button"}${
          this.props.noMargin ? " noMargin" : ""
        }`}
        onClick={() => {
          if (this.props.onClick !== undefined) {
            this.props.onClick();
          }
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
